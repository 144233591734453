import React from 'react';
import { 
  Scale, 
  Shield,
  Gavel,
  Globe,
  Target,
  HeartHandshake,
  Clock
} from 'lucide-react';
import LLSEOMeta from './LLSEOMeta';

const LLWhyUsPage = () => {
  const expertise = [
    {
      icon: <Gavel className="w-12 h-12 text-green-400" />,
      title: "Criminal Law Excellence",
      description: "Expert defense in criminal cases with a proven track record. Our criminal defense attorneys have successfully handled 500+ cases with a 92% favorable outcome rate.",
      area: "Criminal Law"
    },
    {
      icon: <Shield className="w-12 h-12 text-green-400" />,
      title: "Civil Litigation Success",
      description: "Comprehensive civil litigation services with expertise in property disputes, family law, and contractual matters. 95% success rate in civil proceedings.",
      area: "Civil Law"
    },
    {
      icon: <Globe className="w-12 h-12 text-green-400" />,
      title: "Cyber Law Expertise",
      description: "Specialized in digital crime defense, cybersecurity compliance, and online privacy protection. Handled over 100+ cyber crime cases successfully.",
      area: "Cyber Law"
    }
  ];

  const achievements = [
    { 
      value: "92%", 
      label: "Success Rate in Criminal Cases",
      description: "Proven track record in criminal defense" 
    },
    { 
      value: "100+", 
      label: "Cases Successfully Resolved",
      description: "Across criminal, civil, and cyber law" 
    },
    { 
      value: "5+", 
      label: "Years of Legal Excellence",
      description: "Dedicated to justice and client success" 
    },
    { 
      value: "24/7", 
      label: "Emergency Legal Support",
      description: "Always available for urgent cases" 
    }
  ];

  const clientBenefits = [
    {
      icon: <Target className="w-12 h-12 text-green-400" />,
      title: "Comprehensive Legal Defense",
      description: "Expert representation in criminal defense, civil litigation, and cyber crime cases. Our strategic approach ensures the best possible outcomes for our clients."
    },
    {
      icon: <HeartHandshake className="w-12 h-12 text-green-400" />,
      title: "Client-First Approach",
      description: "Round-the-clock access to your legal team, transparent communication, and personalized attention throughout your case."
    },
    {
      icon: <Clock className="w-12 h-12 text-green-400" />,
      title: "Swift Case Resolution",
      description: "Efficient case management and prompt response to legal emergencies. We understand that time is crucial in legal matters."
    }
  ];

  return (
    <>
      <LLSEOMeta 
        title="Criminal, Civil & Cyber Law Experts - LoopholeLaw"
        description="Leading law firm specializing in criminal defense, civil litigation, and cyber law. 92% success rate, 500+ cases resolved, and 15+ years of legal excellence."
        keywords="criminal defense lawyer, civil litigation expert, cyber law specialist, criminal law firm, civil cases, cyber crime defense, legal expertise, top law firm"
      />
      <article className="min-h-screen bg-gradient-to-b from-slate-900 to-black text-white">
        {/* Hero Section */}
        <section className="relative min-h-[50vh] flex items-center justify-center py-16" aria-label="hero">
          <div className="absolute inset-0 bg-green-400/10" />
          <div className="relative z-10 text-center px-4 max-w-4xl mx-auto">
            <div className="relative w-24 h-24 mx-auto mb-8">
              <Scale className="w-24 h-24 text-blue-400" />
            </div>
            <h1 className="text-4xl md:text-5xl font-bold mb-6">
              Expert Legal Defense in Criminal, Civil & Cyber Law
            </h1>
            <p className="text-xl text-gray-300">
              Trust your legal matters to a firm with proven results. 15+ years of excellence,
              500+ successful cases, and a 92% success rate in criminal defense.
            </p>
          </div>
        </section>

        {/* Expertise Section */}
        <section className="py-20 px-4" aria-label="expertise">
          <div className="max-w-6xl mx-auto">
            <h2 className="text-3xl font-bold text-center mb-16">Specialized Legal Practice Areas</h2>
            <div className="grid md:grid-cols-3 gap-8">
              {expertise.map((item, index) => (
                <div key={index} className="p-8 bg-slate-800/50 rounded-xl border border-slate-700 hover:bg-slate-800/70 transition-colors">
                  <div className="flex justify-center mb-6">
                    {item.icon}
                  </div>
                  <h3 className="text-xl font-semibold text-center mb-4">{item.title}</h3>
                  <p className="text-gray-300">{item.description}</p>
                </div>
              ))}
            </div>
          </div>
        </section>

        {/* Achievements Section */}
        <section className="py-20 px-4 bg-slate-900" aria-label="achievements">
          <div className="max-w-6xl mx-auto">
            <h2 className="text-3xl font-bold text-center mb-16">Proven Track Record</h2>
            <div className="grid sm:grid-cols-2 lg:grid-cols-4 gap-8">
              {achievements.map((item, index) => (
                <div key={index} className="p-6 bg-slate-800/50 rounded-xl border border-slate-700 hover:bg-slate-800/70 transition-colors">
                  <div className="text-3xl md:text-4xl font-bold text-green-400 mb-2">
                    {item.value}
                  </div>
                  <div className="text-xl mb-2">{item.label}</div>
                  <div className="text-sm text-gray-400">{item.description}</div>
                </div>
              ))}
            </div>
          </div>
        </section>

        {/* Benefits Section */}
        <section className="py-20 px-4" aria-label="benefits">
          <div className="max-w-6xl mx-auto">
            <h2 className="text-3xl font-bold text-center mb-16">
              Why Choose LoopholeLaw
            </h2>
            <div className="grid md:grid-cols-3 gap-8">
              {clientBenefits.map((benefit, index) => (
                <div key={index} className="p-8 bg-slate-800/50 rounded-xl border border-slate-700 hover:bg-slate-800/70 transition-colors">
                  <div className="flex justify-center mb-6">
                    {benefit.icon}
                  </div>
                  <h3 className="text-xl font-semibold text-center mb-4">{benefit.title}</h3>
                  <p className="text-gray-300">{benefit.description}</p>
                </div>
              ))}
            </div>
          </div>
        </section>

        {/* CTA Section */}
        <section className="py-20 px-4 bg-slate-900" aria-label="contact">
          <div className="max-w-3xl mx-auto text-center">
            <h2 className="text-3xl font-bold mb-6">Get Expert Legal Representation</h2>
            <p className="text-gray-300 mb-8">
              Whether you're facing criminal charges, civil disputes, or cyber crime issues,
              our experienced attorneys are here to protect your rights and interests.
            </p>
            <div className="flex flex-col sm:flex-row gap-4 justify-center">
              <a 
                href="/contact" 
                className="inline-block px-8 py-3 bg-green-500 hover:bg-green-600 rounded-lg font-medium transition-colors"
                rel="nofollow"
              >
                Free Case Evaluation
              </a>
              <a 
                href="/practice-areas" 
                className="inline-block px-8 py-3 border border-white hover:bg-white/10 rounded-lg font-medium transition-colors"
              >
                Explore Practice Areas
              </a>
            </div>
          </div>
        </section>
      </article>
    </>
  );
};

export default LLWhyUsPage;