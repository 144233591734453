import React, { useState, useEffect } from 'react';
import { Mail, Award, BookOpen, Scale, Loader2 } from 'lucide-react';
import LLSEOMeta from '../LLSEOMeta';

// Custom LinkedIn Icon as SVG
const LinkedInIcon = () => (
  <svg 
    xmlns="http://www.w3.org/2000/svg" 
    className="w-5 h-5"
    viewBox="0 0 24 24" 
    fill="none" 
    stroke="currentColor" 
    strokeWidth="2" 
    strokeLinecap="round" 
    strokeLinejoin="round"
  >
    <path d="M16 8a6 6 0 0 1 6 6v7h-4v-7a2 2 0 0 0-2-2 2 2 0 0 0-2 2v7h-4v-7a6 6 0 0 1 6-6z" />
    <rect x="2" y="9" width="4" height="12" />
    <circle cx="4" cy="4" r="2" />
  </svg>
);

const ImageWithLoading = ({ src, alt, className }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(false);

  return (
    <div className="relative">
      {isLoading && (
        <div className="absolute inset-0 flex items-center justify-center bg-slate-800">
          <Loader2 className="w-8 h-8 text-blue-400 animate-spin" />
        </div>
      )}
      <img 
        src={src} 
        alt={alt}
        className={className}
        loading="lazy"
        onLoad={() => setIsLoading(false)}
        onError={(e) => {
          setIsLoading(false);
          setError(true);
          e.target.src = '/assets/placeholder-attorney.webp';
        }}
        style={{ opacity: isLoading ? 0 : 1 }}
      />
    </div>
  );
};

const LLAttorneysPage = () => {
  const [activeTab, setActiveTab] = useState('all');
  const [pageLoaded, setPageLoaded] = useState(false);

  useEffect(() => {
    // Simulate initial page load
    setPageLoaded(true);
  }, []);

  const attorneys = [
    {
      id: 1,
      name: "Adv. Anandu A B",
      image: "/assets/Anandu_A_B.webp",
      position: "Senior Partner",
      mailId: "youngadv1997@gmail.com",
      linkedinUrl: "https://www.linkedin.com/in/anandu-a-b-1a07661b9",
      category: "partner",
      expertise: ["Criminal Law", "Cyber Law", "Civil Law", "Consumer Law", "Banking Law", 
      "Property Law", "Family Law", "Claim Cases", "Cheque Bounce Cases"],
      education: [
        "BA LL.B., Sree Narayana Guru College of Legal Studies, Kollam"
      ],
      barAdmissions: [
        "Bar Council of Kerala",
        "Kerala District Court, Kollam"
      ],
      experience: "5+ years",
      awards: [],
      bio: "Anandu is a seasoned Lawyer with 5 years of experience delivering tailored legal solutions and providing clients with strategic counsel and effective representation. He has successfully argued several landmark cases.",
    },
    {
      id: 2,
      name: "Adv. Sreeshma Suresh",
      image: "/assets/Sreeshma_Suresh.webp",
      mailId: "sreeshmassuresh27@gmail.com",
      linkedinUrl: "https://www.linkedin.com/in/adv-sreeshma-suresh-5238bb229",
      position: "Managing Partner",
      category: "partner",
      expertise: ["Civil Law", "Registration Law", "Family Law", "Cheque Bounce Cases"],
      education: [
        "BA LL.B., Sree Narayana Guru College of Legal Studies, Kollam"
      ],
      barAdmissions: [
        "Bar Council of Kerala"
      ],
      experience: "5+ years",
      awards: [],
      bio: "Sreeshma specializes in civil and family law, bringing a compassionate and thorough approach to every case."
    },
    {
      id: 3,
      name: "Adv. Arjun A B",
      image: "/assets/Arjun_A_B.webp",
      mailId: "abarjun854@gmail.com",
      linkedinUrl: "https://www.linkedin.com/in/arjun-ab-224048329",
      position: "Associate",
      category: "associate",
      expertise: ["Criminal Law", "Civil Law", "Cyber Law", "Cheque Bounce Cases"],
      education: [
        "BA LL.B., Sree Narayana Guru College of Legal Studies, Kollam"
      ],
      barAdmissions: [
        "Bar Council of Kerala",
        "Kerala District Court, Kollam"
      ],
      experience: "5+ years",
      awards: [],
      bio: "Arjun is an emerging legal professional specializing in criminal defense and civil law matters."
    }
  ];

  const categories = [
    { id: 'all', label: 'All Attorneys' },
    { id: 'partner', label: 'Partners' },
    { id: 'associate', label: 'Associates' }
  ];

  const filteredAttorneys = activeTab === 'all' 
    ? attorneys 
    : attorneys.filter(attorney => attorney.category === activeTab);

  return (
    <>
      <LLSEOMeta 
        title="Legal Team & Attorneys"
        description="Meet LoopholeLaw's experienced attorneys in Kollam, Kerala. Our team specializes in criminal, civil, family, and cyber law with expertise in providing comprehensive legal solutions across Kerala courts."
        keywords="Kerala lawyers, Kollam attorneys, criminal lawyers Kerala, civil law experts, family law attorneys Kerala, cyber law specialists, legal team Kollam, court lawyers Kerala, experienced attorneys Kerala, LoopholeLaw team"
      />
      <div className="bg-slate-900">
        <div className="min-h-screen pt-24 pb-12">
          <div className="max-w-7xl mx-auto px-4">
            {/* Header Section */}
            <div className="text-center mb-16">
              <h1 className="text-4xl md:text-5xl font-bold text-white mb-6">
                Our Legal Team
              </h1>
              <p className="text-xl text-gray-400 max-w-3xl mx-auto">
                Meet our experienced attorneys who are dedicated to providing exceptional 
                legal services and protecting your interests.
              </p>
            </div>

            {/* Category Filter */}
            <div className="flex justify-center mb-12">
              <div className="inline-flex bg-slate-800/50 rounded-lg p-1">
                {categories.map(category => (
                  <button
                    key={category.id}
                    onClick={() => setActiveTab(category.id)}
                    className={`px-6 py-2 rounded-lg transition-colors ${
                      activeTab === category.id 
                        ? 'bg-green-500 text-white' 
                        : 'text-gray-400 hover:text-white'
                    }`}
                  >
                    {category.label}
                  </button>
                ))}
              </div>
            </div>

            {/* Attorneys Grid */}
            <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
              {filteredAttorneys.map(attorney => (
                <div 
                  key={attorney.id}
                  className="bg-slate-800/50 rounded-xl overflow-hidden border border-slate-700 group hover:border-green-500/50 transition-colors"
                >
                  {/* Attorney Image */}
                  <div className="aspect-square relative overflow-hidden">
                    <ImageWithLoading 
                      src={attorney.image} 
                      alt={attorney.name}
                      className="w-full h-full object-cover group-hover:scale-105 transition-transform duration-300"
                    />
                    <div className="absolute inset-0 bg-gradient-to-t from-black/80 to-transparent" />
                    <div className="absolute bottom-4 left-4 right-4">
                      <h3 className="text-xl font-bold text-white mb-1">{attorney.name}</h3>
                      <p className="text-green-400">{attorney.position}</p>
                    </div>
                  </div>

                  {/* Attorney Details */}
                  <div className="p-6 space-y-4">
                    {/* Expertise */}
                    <div>
                      <h4 className="text-white font-semibold mb-2 flex items-center gap-2">
                        <Scale className="w-4 h-4 text-green-400" />
                        Areas of Expertise
                      </h4>
                      <div className="flex flex-wrap gap-2">
                        {attorney.expertise.map((area, index) => (
                          <span 
                            key={index}
                            className="px-3 py-1 bg-slate-700 rounded-full text-sm text-gray-300"
                          >
                            {area}
                          </span>
                        ))}
                      </div>
                    </div>

                    {/* Education */}
                    <div>
                      <h4 className="text-white font-semibold mb-2 flex items-center gap-2">
                        <BookOpen className="w-4 h-4 text-green-400" />
                        Education
                      </h4>
                      <ul className="text-gray-400 text-sm space-y-1">
                        {attorney.education.map((edu, index) => (
                          <li key={index}>{edu}</li>
                        ))}
                      </ul>
                    </div>

                    {/* Bio */}
                    <p className="text-gray-300 text-sm">
                      {attorney.bio}
                    </p>

                    {/* Contact */}
                    <div className="flex gap-4 pt-4">
                      <a 
                        href={'mailto:' + attorney.mailId}
                        className="p-2 rounded-lg bg-slate-700 hover:bg-green-500 text-white transition-colors"
                      >
                        <Mail className="w-5 h-5" />
                      </a>
                      <a 
                        href={attorney.linkedinUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="p-2 rounded-lg bg-slate-700 hover:bg-green-500 text-white transition-colors"
                      >
                        <LinkedInIcon />
                      </a>
                    </div>
                  </div>
                </div>
              ))}
            </div>

            {/* Contact CTA */}
            <div className="mt-16 text-center bg-slate-800/50 rounded-xl p-8 border border-slate-700">
              <h2 className="text-2xl font-bold text-white mb-4">
                Need Legal Assistance?
              </h2>
              <p className="text-gray-400 mb-6">
                Our team of experienced attorneys is ready to help you with your legal matters.
              </p>
              <a 
                href="/consultation"
                className="inline-block px-8 py-3 bg-green-500 hover:bg-green-600 text-white font-medium rounded-lg transition-colors"
              >
                Schedule a Consultation
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LLAttorneysPage;