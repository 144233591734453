import React from 'react';

const LLSEOMeta = ({ title, description, keywords }) => {
  return (
    <>
      <title>{title} | LoopholeLaw</title>
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords} />
      
      {/* Open Graph / Facebook */}
      <meta property="og:type" content="website" />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      
      {/* Twitter */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      
      {/* Indexing directives */}
      <meta name="robots" content="index, follow" />
      <link rel="canonical" href={window.location.href} />
    </>
  );
};

export default LLSEOMeta;