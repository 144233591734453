import React from 'react';
import { 
  Gavel,
  Globe,
  Scale,
  Users,
  Building2,
  FileText,
  Landmark,
  FileCheck,
  Receipt,
  ArrowRight,
  GraduationCap,
  LibraryIcon,
  Shield
} from 'lucide-react';
import LLSEOMeta from './LLSEOMeta';

const LLServicesPage = () => {
  const practiceAreas = [
    {
      icon: <Gavel className="w-12 h-12 text-blue-400" />,
      title: "Criminal Law",
      description: "Expert defense in criminal matters and investigations",
      services: [
        "Criminal Defense",
        "White Collar Crimes",
        "Bail Applications",
        "Criminal Appeals",
        "Police Investigations",
        "Court Representation"
      ]
    },
    {
      icon: <Globe className="w-12 h-12 text-blue-400" />,
      title: "Cyber Law",
      description: "Protection and representation in digital legal matters",
      services: [
        "Cybercrime Defense",
        "Digital Evidence Handling",
        "Online Privacy Issues",
        "Data Protection",
        "Internet Fraud Cases",
        "Digital Rights Management"
      ]
    },
    {
      icon: <Scale className="w-12 h-12 text-blue-400" />,
      title: "Civil Law",
      description: "Comprehensive civil litigation services",
      services: [
        "Civil Disputes",
        "Property Disputes",
        "Contract Disputes",
        "Civil Appeals",
        "Injunctions",
        "Damage Claims"
      ]
    },
    {
      icon: <Users className="w-12 h-12 text-blue-400" />,
      title: "Family Law",
      description: "Sensitive handling of family legal matters",
      services: [
        "Divorce Proceedings",
        "Child Custody",
        "Maintenance Cases",
        "Domestic Violence",
        "Marriage Registration",
        "Family Settlements"
      ]
    },
    {
      icon: <Landmark className="w-12 h-12 text-blue-400" />,
      title: "Banking Law",
      description: "Expert guidance in banking and financial matters",
      services: [
        "Loan Agreements",
        "Recovery Proceedings",
        "Bank Fraud Cases",
        "SARFAESI Matters",
        "Financial Disputes",
        "Banking Documentation"
      ]
    },
    {
      icon: <FileCheck className="w-12 h-12 text-blue-400" />,
      title: "Registration Law",
      description: "Comprehensive registration and documentation services",
      services: [
        "Property Registration",
        "Document Registration",
        "Partnership Registration",
        "Society Registration",
        "Trust Registration",
        "Legal Documentation"
      ]
    },
    {
      icon: <Building2 className="w-12 h-12 text-blue-400" />,
      title: "Corporate Law",
      description: "Legal solutions for businesses of all sizes",
      services: [
        "Company Formation",
        "Corporate Compliance",
        "Business Agreements",
        "Corporate Restructuring",
        "Due Diligence",
        "Board Matters"
      ]
    },
    {
      icon: <Shield className="w-12 h-12 text-blue-400" />,
      title: "Intellectual Property",
      description: "Protection of innovative assets and creations",
      services: [
        "Trademark Registration",
        "Copyright Protection",
        "Patent Filing",
        "IP Litigation",
        "IP Portfolio Management",
        "IP Licensing"
      ]
    },
    {
      icon: <Receipt className="w-12 h-12 text-blue-400" />,
      title: "Tax Law",
      description: "Strategic tax planning and dispute resolution",
      services: [
        "Tax Planning",
        "GST Matters",
        "Income Tax",
        "Tax Appeals",
        "Tax Compliance",
        "Tax Litigation"
      ]
    }
  ];

  const expertiseAreas = [
    "Criminal Defense",
    "Cybersecurity & Digital Law",
    "Civil Litigation",
    "Family Legal Matters",
    "Banking & Finance",
    "Corporate Affairs"
  ];

  return (
    <>
      <LLSEOMeta 
        title="Legal Services & Practice Areas - LoopholeLaw"
        description="Specialized legal services in Criminal, Cyber, Civil, Family, Banking, Registration, Corporate, IP, and Tax Law. Expert attorneys delivering comprehensive legal solutions."
        keywords="criminal law, cyber law, civil litigation, family law, banking law, registration services, corporate law, intellectual property, tax law, legal services"
      />
      <article className="min-h-screen bg-gradient-to-b from-slate-900 to-black text-white">
        {/* Hero Section */}
        <section className="relative h-[50vh] flex items-center justify-center" aria-label="introduction">
          <div className="absolute inset-0 bg-blue-400/10" />
          <div className="relative z-10 text-center px-4">
            <Scale className="w-16 h-16 text-blue-400 mx-auto mb-6" />
            <h1 className="text-4xl md:text-5xl font-bold mb-6">
              Specialized Legal Services
            </h1>
            <p className="text-xl text-gray-300 max-w-3xl mx-auto">
              Expert legal representation across criminal, civil, and corporate matters, 
              delivering comprehensive solutions for all your legal needs.
            </p>
          </div>
        </section>

        {/* Practice Areas Grid */}
        <section className="py-20 px-4" aria-label="practice-areas">
          <div className="max-w-7xl mx-auto">
            <h2 className="text-3xl font-bold text-center mb-16">Our Practice Areas</h2>
            <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
              {practiceAreas.map((area, index) => (
                <div key={index} className="bg-slate-800/50 rounded-xl border border-slate-700 overflow-hidden hover:border-blue-400/50 hover:bg-slate-800/70 transition-colors">
                  <div className="p-8">
                    <div className="flex justify-center mb-6">
                      {area.icon}
                    </div>
                    <h3 className="text-2xl font-semibold text-center mb-4">{area.title}</h3>
                    <p className="text-gray-300 text-center mb-6">{area.description}</p>
                    
                    <div className="space-y-3">
                      {area.services.map((service, idx) => (
                        <div key={idx} className="flex items-center gap-2">
                          <ArrowRight className="w-4 h-4 text-blue-400 flex-shrink-0" />
                          <span className="text-gray-300">{service}</span>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>

        {/* Expertise Areas */}
        <section className="py-20 px-4 bg-slate-900" aria-label="expertise">
          <div className="max-w-6xl mx-auto text-center">
            <h2 className="text-3xl font-bold mb-16">Core Expertise</h2>
            <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-6">
              {expertiseAreas.map((expertise, index) => (
                <div key={index} className="p-6 bg-slate-800/50 rounded-xl border border-slate-700 hover:bg-slate-800/70 transition-colors">
                  <GraduationCap className="w-8 h-8 text-blue-400 mx-auto mb-4" />
                  <h3 className="text-xl font-semibold">{expertise}</h3>
                </div>
              ))}
            </div>
          </div>
        </section>

        {/* Client Benefits */}
        <section className="py-20 px-4" aria-label="benefits">
          <div className="max-w-6xl mx-auto">
            <h2 className="text-3xl font-bold text-center mb-16">The LoopholeLaw Advantage</h2>
            <div className="grid md:grid-cols-3 gap-8">
              <div className="p-6 bg-slate-800/50 rounded-xl border border-slate-700 hover:bg-slate-800/70 transition-colors">
                <FileText className="w-12 h-12 text-blue-400 mx-auto mb-4" />
                <h3 className="text-xl font-semibold text-center mb-4">Expert Counsel</h3>
                <p className="text-gray-300 text-center">
                  Specialized legal expertise across multiple practice areas with proven track record.
                </p>
              </div>
              <div className="p-6 bg-slate-800/50 rounded-xl border border-slate-700 hover:bg-slate-800/70 transition-colors">
                <LibraryIcon className="w-12 h-12 text-blue-400 mx-auto mb-4" />
                <h3 className="text-xl font-semibold text-center mb-4">Comprehensive Solutions</h3>
                <p className="text-gray-300 text-center">
                  End-to-end legal support from consultation to resolution of your case.
                </p>
              </div>
              <div className="p-6 bg-slate-800/50 rounded-xl border border-slate-700 hover:bg-slate-800/70 transition-colors">
                <Users className="w-12 h-12 text-blue-400 mx-auto mb-4" />
                <h3 className="text-xl font-semibold text-center mb-4">Client Focus</h3>
                <p className="text-gray-300 text-center">
                  Dedicated personal attention and regular updates throughout your case.
                </p>
              </div>
            </div>
          </div>
        </section>

        {/* CTA Section */}
        <section className="py-20 px-4 bg-slate-900" aria-label="contact">
          <div className="max-w-3xl mx-auto text-center">
            <h2 className="text-3xl font-bold mb-6">Discuss Your Legal Matter</h2>
            <p className="text-gray-300 mb-8">
              Our experienced team is ready to assist you with any legal challenges.
              Schedule a consultation to discuss your case with our experts.
            </p>
            <div className="flex justify-center">
              <a 
                href="/consultation" 
                className="inline-block px-8 py-3 bg-blue-500 hover:bg-blue-600 rounded-lg font-medium transition-colors"
              >
                Schedule Consultation
              </a>
            </div>
          </div>
        </section>
      </article>
    </>
  );
};

export default LLServicesPage;